import React, { useState } from 'react';
import axios from 'axios';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import './css/style.css';

const Home =()=> {
    const [status, setStatus] = useState('');
    function updateDatabase(e) {        
        e.preventDefault();
        const dynalistapikey = e.target.dynalist_api_key.value;
        const dynalistdocumentid = e.target.dynalist_document_id.value;
        const airtableapikey = e.target.airtable_api_key.value;
        const airtabledocumentid = e.target.airtable_document_id.value;
        const greentag = e.target.green_tag.value;
        const yellowtag = e.target.yellow_tag.value;
        const orangetag = e.target.orange_tag.value;
        const redtag = e.target.red_tag.value;
        const bluetag = e.target.blue_tag.value;
        const violettag = e.target.violet_tag.value;
        const browntag = e.target.brown_tag.value;
        const blacktag = e.target.black_tag.value;

        const config = {
             "headers" : {
                "dynalist-api-key": dynalistapikey,
                "dynalist-document-id": dynalistdocumentid,
                "airtable-api-key": airtableapikey,
                "airtable-document-id": airtabledocumentid,
                "green-tag": greentag,
                "yellow-tag": yellowtag,
                "orange-tag": orangetag,
                "red-tag": redtag,
                "blue-tag": bluetag,
                "violet-tag": violettag,
                "brown-tag": browntag,
                "black-tag": blacktag,
            }
        }

        axios.post('https://dev-hemant.api.moomoot.fr/airtable/insert-data','',config)
        .then(
            response => {
                const result = response.data;
                console.log(result);
                if(result.status === 'Success'){
                    setStatus("Updated successfully.")
                }else{
                    setStatus("Something went wrong.")
                }
            }
        )

    }

    return(
        <div className='details-form'>
            <h1>Update credentials for Airtable-Dynalist integration.</h1>

            <form className="contact-form" onSubmit={updateDatabase}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="dynalist_api_key"
                            variant="outlined"
                            required
                            fullWidth
                            id="dynalist_api_key"
                            label="Dynalist API KEY"
                            placeholder="ABCDEF12345..."
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="dynalist_document_id"
                            variant="outlined"
                            required
                            fullWidth
                            id="dynalist_document_id"
                            label="Dynalist Document ID"
                            placeholder="ANIWGFIOWBOU..."
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="airtable_api_key"
                            variant="outlined"
                            required
                            fullWidth
                            id="airtable_api_key"
                            label="Airtable API KEY"
                            placeholder="12345AWFA.."
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="airtable_document_id"
                            variant="outlined"
                            required
                            fullWidth
                            id="airtable_document_id"
                            label="Airtable Document ID"
                            placeholder="XCCIWGFIOWBOU..."
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="green_tag"
                            variant="outlined"
                            required
                            fullWidth
                            id="green_tag"
                            label="Green Tag"
                            placeholder="Done"
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="yellow_tag"
                            variant="outlined"
                            required
                            fullWidth
                            id="yellow_tag"
                            label="Yellow Tag"
                            placeholder="In progress"
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="orange_tag"
                            variant="outlined"
                            required
                            fullWidth
                            id="orange_tag"
                            label="Orange Tag"
                            placeholder="Planned"
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="red_tag"
                            variant="outlined"
                            required
                            fullWidth
                            id="red_tag"
                            label="Red Tag"
                            placeholder="Blocker"
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="blue_tag"
                            variant="outlined"
                            required
                            fullWidth
                            id="blue_tag"
                            label="Blue Tag"
                            placeholder="Not planned"
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="violet_tag"
                            variant="outlined"
                            required
                            fullWidth
                            id="violet_tag"
                            label="Violet Tag"
                            placeholder="planned"
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="brown_tag"
                            variant="outlined"
                            required
                            fullWidth
                            id="brown_tag"
                            label="Brown Tag"
                            placeholder="Testing"
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <TextField
                            name="black_tag"
                            variant="outlined"
                            required
                            fullWidth
                            id="black_tag"
                            label="Black Tag"
                            placeholder="Bugs"
                            // style={{width:"50%"}}
                            size="small"
                        />
                    </Grid>
                    <div className="submit-button-container">
                        <Button
                            className="submit-button"
                            type="submit"
                            // disabled={!isValid}
                            //   fullWidth
                            variant="contained"
                            color="primary"
                            // disabled={disablebutton}
                        >
                            update database
                        </Button>
                    </div>
                </Grid>
            </form>
            {
                status.length>0?
                    <h3>{status}</h3>
                :
                <span></span>
            }
        </div>
    )
}

export default Home;